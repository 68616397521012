import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import HomeView from "@/views/HomeView.vue";
import FindStoreView from "@/views/FindStoreView.vue";
import StoreView from "@/views/StoreView.vue";
import DiningView from "@/views/DiningView.vue";
import WhatsOnView from "@/views/WhatsOnView.vue";
import FindOfferView from "@/views/FindOfferView";
import EventView from "@/views/EventView.vue";
import DefibView from "@/views/DefibView.vue";
import ParkingView from "@/views/ParkingView.vue";
import ToiletListView from "@/views/ToiletListView.vue";
import BillyChipView from "@/views/BillyChipView.vue";
import UserOffersView from "@/views/UserOffersView.vue";
import NotAuthorised from "@/views/NotAuthorised.vue";
import AdminUnavailable from "@/views/AdminViewUnavailable.vue";
// import B2cRegister from "@/views/B2cRegister.vue";
//ADMIN IMPORT
//import AdminLoginView from '@/views/admin/AdminLoginView.vue'
import AdminDashboardView from "@/views/admin/AdminDashboardView.vue";
import AdminOffersView from "@/views/admin/AdminOffersView.vue";
import AdminMyStoreView from "@/views/admin/AdminMyStoreView.vue";
import AdminCustomersView from "@/views/admin/AdminCustomersView.vue";
import AdminDefibView from "@/views/admin/AdminDefibView.vue";
import AdminSocialView from "@/views/admin/AdminSocialView.vue";
import AdminContactsView from "@/views/admin/AdminContactsView.vue";
import AdminUsersView from "@/views/admin/AdminUsersView.vue";
import AdminEventsView from "@/views/admin/AdminEventsView.vue";
import AdminStoreView from "@/views/admin/AdminStoreView.vue";
import AdminBidMembers from "@/views/admin/AdminBidMembers.vue";
import AdminRegisterView from "@/views/admin/AdminRegisterView.vue";
import ShopAdminRegisterView from "@/views/admin/ShopAdminRegisterView.vue";
// import AdminScanView from '@/views/admin/AdminScanView.vue'
import AdminCardsView from "@/views/admin/AdminCardsView.vue";
import AdminAdminUsersView from "@/views/admin/AdminAdminUsersView.vue";
//ETC IMPORT
import store from "@/plugins/store.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  // { path: '/b2c-register', name: 'B2cRegister', component: B2cRegister, meta: {requiresAuth: false, requiresSuperAdmin: false, requiresStoreAdmin: false}},
  {
    path: "/adminUnavailable",
    name: "AdminUnavailable",
    component: AdminUnavailable,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/notAuthorised",
    name: "NotAuthorised",
    component: NotAuthorised,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/admin-register",
    name: "AdminRegisterView",
    component: AdminRegisterView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/business-admin-register",
    name: "ShopAdminRegisterView",
    component: ShopAdminRegisterView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/home",
    name: "HomeView",
    component: HomeView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/",
    name: "FindStoreView",
    component: FindStoreView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/business/:entityId",
    name: "StoreView",
    component: StoreView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/dining-out",
    name: "DiningView",
    component: DiningView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/billy-chip",
    name: "BillyChipView",
    component: BillyChipView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/whats-on",
    name: "WhatsOnView",
    component: WhatsOnView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/offers",
    name: "FindOfferView",
    component: FindOfferView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/my-offers",
    name: "UserOffersView",
    component: UserOffersView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/event/:entityId",
    name: "EventView",
    component: EventView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/Defibrillators",
    name: "DefibView",
    component: DefibView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/Parking",
    name: "ParkingView",
    component: ParkingView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/toilets",
    name: "ToiletListView",
    component: ToiletListView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: false,
    },
  },
  // { path: '/admin', name: 'AdminLogin', component: AdminLoginView },
  {
    path: "/admin/dashboard",
    name: "AdminDashboardView",
    component: AdminDashboardView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: true,
    },
  },
  {
    path: "/admin/offers",
    name: "AdminOffersView",
    component: AdminOffersView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: true,
    },
  },
  {
    path: "/admin/mystore",
    name: "AdminMyStoreView",
    component: AdminMyStoreView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: true,
    },
  },
  {
    path: "/admin/customers",
    name: "AdminCustomersView",
    component: AdminCustomersView,
    meta: { requiresAuth: true, requiresSuperAdmin: true },
  },
  {
    path: "/admin/Defibrillators",
    name: "AdminDefibView",
    component: AdminDefibView,
    meta: { requiresAuth: true, requiresSuperAdmin: true },
  },
  {
    path: "/admin/news",
    name: "AdminSocialView",
    component: AdminSocialView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: true,
    },
  },
  {
    path: "/admin/Contacts",
    name: "AdminContactsView",
    component: AdminContactsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: true,
    },
  },
  {
    path: "/admin/Users",
    name: "AdminUsersView",
    component: AdminUsersView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/admin/Admin-Users",
    name: "AdminAdminUsersView",
    component: AdminAdminUsersView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/admin/events",
    name: "AdminEventsView",
    component: AdminEventsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: true,
    },
  },
  {
    path: "/admin/businesses",
    name: "AdminStoreView",
    component: AdminStoreView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      requiresStoreAdmin: false,
    },
  },
  {
    path: "/admin/bidmembers",
    name: "AdminBidMembers",
    component: AdminBidMembers,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      requiresStoreAdmin: false,
    },
  },
  // { path: '/admin/scan', name: 'AdminScanView', component: AdminScanView, meta: { requiresAuth: true, requiresSuperAdmin: false, requiresStoreAdmin: true } },
  {
    path: "/admin/cards",
    name: "AdminCardsView",
    component: AdminCardsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
      requiresStoreAdmin: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
});

router.beforeEach(async (to, from, next) => {
  // Function to detect if the user is on a mobile device
  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  // Redirect to AdminUnavailable if the path includes "admin" and the user is on a mobile device

  if (to.meta.requiresAuth) {
    let userProfile = store.state.currentUserProfile || { status: "Approved" };

    const checkAuthorization = (allowedLevels) => {
      if (
        allowedLevels.includes(userProfile.level) &&
        userProfile.status === "Approved"
      ) {
        if (to.path.includes("admin") && isMobile() && userProfile.level !== 'shopadmin') {
          // Only allow 'shopadmin' on mobile for admin paths
          return next({ name: "AdminUnavailable" });
        } else {
          next();
        }
      } else {
        next({ name: "NotAuthorised" });
      }
    };

    if (to.meta.requiresSuperAdmin) {
      checkAuthorization(["superadmin"]);
    } else if (to.meta.requiresStoreAdmin) {
      checkAuthorization(["shopadmin", "superadmin"]);
    } else {
      if (userProfile.status === "Approved") {
        if (to.path.includes("admin") && isMobile() && userProfile.level !== 'shopadmin') {
          return next({ name: "AdminUnavailable" });
        } else {
          next();
        }
      } else {
        next({ name: "NotAuthorised" });
      }
    }
  } else {
    next();
  }
});

export default router;
