import { render, staticRenderFns } from "./AdminEventsView.vue?vue&type=template&id=0230d0d6&"
import script from "./AdminEventsView.vue?vue&type=script&lang=js&"
export * from "./AdminEventsView.vue?vue&type=script&lang=js&"
import style0 from "./AdminEventsView.vue?vue&type=style&index=0&id=0230d0d6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports