<template>
  <div>
    <div class="d-flex justify-center align-center pt-4">
      <v-img
        @click="MIX_go('/home')"
        src="@/assets/Big-Heart-Logo-Red.jpg"
        contain
        class="headericon"
      />
    </div>
    <!-- <pre> EID: {{ $route.params.entityId }}</pre> -->
    <div class="pb-3">
      <v-btn depressed class="white d-flex align-center" @click="$router.go(-1)">
        <v-icon>mdi-chevron-left</v-icon>
        <span class="pl-2">{{ $t("back") }}</span>
      </v-btn>
    </div>
    <v-container style="padding-left: 3em !important; padding-right: 3em !important">
      <v-overlay :opacity="0.2" :value="loading" color="lightgrey">
        <v-progress-circular indeterminate size="64" color="primary"> </v-progress-circular>
      </v-overlay>
      <v-row class="dense lightgrey rounded-of">
        <!--                <v-col cols="12" class="text-center">-->
        <!--                    <v-img max-height="100%" max-width="100%"-->
        <!--                        class="rounded-of imgpopout dropshadow" :src='storeIMG' :lazy-src="MIX_getImgUrl('StoreHoldingBanner.jpg')"></v-img>-->
        <!--                </v-col>-->
        <v-col cols="12" class="">
          <v-row class="pa-3">
            <v-col cols="12">
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2" class="">
                  <v-img class="rounded-of dropshadow store-img" :src="storeIMG"></v-img>
                </v-col>
                <v-col cols="12" md="6" sm="12" lg="7" xl="7">
                  <div class="title text-left">
                    {{ storedetails.data.name }}
                  </div>
                  <!-- Have to render both strings for locale to work -->
                  <div
                    class="subtitle-1 text-left font-weight-bold"
                    :class="statusColor"
                    v-if="storeStatus === 'N/A'"
                  >
                    N/A
                  </div>
                  <div
                    class="subtitle-1 text-left font-weight-bold"
                    :class="statusColor"
                    v-if="storeStatus === 'Open'"
                  >
                    {{ $t("open") }}
                  </div>
                  <div
                    class="subtitle-1 text-left font-weight-bold"
                    :class="statusColor"
                    v-if="storeStatus === 'Closed'"
                  >
                    {{ $t("closed") }}
                  </div>
                  <div class="accessibility">
                    <!-- <h4 class="subtitle-1">Accessibility</h4> -->
                    <div
                      class="marker-list"
                      v-if="storedetails.data.businessAccessibilities?.length"
                    >
                      <!-- <img src="@/assets/icons/accepts_gift_card.png" /> -->
                      <v-tooltip
                        v-for="tag in filteredAccessibilities"
                        :key="tag"
                        top
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div class="marker">
                            <img
                              v-bind="attrs"
                              v-on="on"
                              :src="require(`@/assets/icons/${tag}.png`)"
                              :alt="tag"
                              v-if="tag === 'offer_available'"
                              @click="showStoreCoupons()"
                            />
                            <img
                              v-bind="attrs"
                              v-on="on"
                              :src="require(`@/assets/icons/${tag}.png`)"
                              :alt="tag"
                              v-else-if="tag != 'billy_chip_seller'"
                            />
                            <v-icon v-bind="attrs" v-on="on" class="pa-2 white--text" v-else
                              >icons8-chip</v-icon
                            >
                          </div>
                        </template>
                        <span>{{ accessibilityTags[tag] }}</span>
                      </v-tooltip>
                      <!-- <div class="marker" v-for="tag in accessibilityTags" :key="tag.value">
                        <img
                          :src="require(`@/assets/icons/${tag.value}.png`)"
                          :alt="tag.label"
                          v-if="tag.value != 'billy_chip_seller'"
                        />
                        <v-icon class="pa-2 white--text" v-else>icons8-chip</v-icon>
                      </div> -->
                      <!-- <div class="marker">
                        
                      </div> -->
                    </div>
                    <div v-else>
                      <p>N/A</p>
                    </div>
                  </div>
                  <div class="social-links">
                    <h4 class="subtitle-1">Social Links</h4>
                    <div class="marker-list">
                      <v-tooltip top color="primary" v-if="storedetails.data.businessWebsite">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="marker">
                            <a
                              :href="storedetails.data.businessWebsite"
                              class="marker"
                              v-bind="attrs"
                              v-on="on"
                              target="_blank"
                            >
                              <v-icon class="white--text link">icons8-shop</v-icon>
                            </a>
                          </div>
                        </template>
                        <span>Website</span>
                      </v-tooltip>
                      <v-tooltip top color="primary" v-if="storedetails.data.businessFacebook">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="marker">
                            <a
                              :href="storedetails.data.businessFacebook"
                              class="marker"
                              v-bind="attrs"
                              v-on="on"
                              target="_blank"
                            >
                              <v-icon class="white--text link">icons8-facebook</v-icon>
                            </a>
                          </div>
                        </template>
                        <span>Facebook</span>
                      </v-tooltip>
                      <v-tooltip top color="primary" v-if="storedetails.data.businessInstagram">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="marker">
                            <a
                              :href="storedetails.data.businessInstagram"
                              class="marker"
                              v-bind="attrs"
                              v-on="on"
                              target="_blank"
                            >
                              <v-icon class="white--text link">icons8-instagram</v-icon>
                            </a>
                          </div>
                        </template>
                        <span>Instagram</span>
                      </v-tooltip>
                      <v-tooltip top color="primary" v-if="storedetails.data.businessTwitter">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="marker">
                            <a
                              :href="storedetails.data.businessTwitter"
                              class="marker"
                              v-bind="attrs"
                              v-on="on"
                              target="_blank"
                            >
                              <v-icon class="white--text link">icons8-twitter</v-icon>
                            </a>
                          </div>
                        </template>
                        <span>Twitter(X)</span>
                      </v-tooltip>
                      <v-tooltip top color="primary" v-if="storedetails.data.businessLinkedin">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="marker">
                            <a
                              :href="storedetails.data.businessLinkedin"
                              class="marker"
                              v-bind="attrs"
                              v-on="on"
                              target="_blank"
                            >
                              <v-icon class="white--text link">icons8-linkedin</v-icon>
                            </a>
                          </div>
                        </template>
                        <span>Linkedin</span>
                      </v-tooltip>
                      <v-tooltip top color="primary" v-if="storedetails.data.businessTiktok">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="marker">
                            <a
                              :href="storedetails.data.businessTiktok"
                              class="marker"
                              v-bind="attrs"
                              v-on="on"
                              target="_blank"
                            >
                              <v-icon class="white--text link">icons8-tiktok</v-icon>
                            </a>
                          </div>
                        </template>
                        <span>Tiktok</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="3" sm="12" lg="3" xl="3" class="d-flex justify-end align-end">
                  <v-icon class="black--text" large>{{
                    typeToIcon(storedetails.data.type)
                  }}</v-icon>
                  <v-icon
                    @click="toggleFavourite()"
                    v-if="!userFavourited && MIX_isLoggedIn()"
                    class="primary--text pl-1"
                    large
                    >icons8-favorite</v-icon
                  >
                  <v-icon
                    @click="toggleFavourite()"
                    v-if="userFavourited && MIX_isLoggedIn()"
                    class="primary--text pl-1"
                    large
                    >icons8-heart</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="6"
              v-for="(offer, index) in storeOffers"
              :key="index"
              class=""
            >
              <OfferCard
                :datadown="offer"
                claimbutton
                v-if="storeOffers.length > 0 && storeCouponVisible"
              >
                <template v-slot:toptitle>{{ offer.offer }}</template>
                <template v-slot:bottomtitle>{{ offer.store }}</template>
              </OfferCard>
            </v-col>
            <v-col cols="12" class="d-flex flex-wrap">
              <v-col cols="12" xs="12" sm="12" lg="6" xl="6" class="">
                <div class="pa-3 darkgrey rounded-of text-center" style="min-height: 100%">
                  <div class="title text-center font-weight-bold pb-5 pt-3">Business Address:</div>
                  <div class="title">
                    {{ storedetails.data.businessAddressLine1 }}
                  </div>
                  <div class="title">
                    {{ storedetails.data.businessAddressLine2 }}
                  </div>
                  <div class="title">{{ storedetails.data.businessTown }}</div>
                  <div class="title">
                    {{ storedetails.data.businessPostcode }}
                  </div>
                </div>
              </v-col>
              <v-col cols="12" xs="10" sm="12" lg="6" xl="6">
                <StoreTable :datadown="storedetails.data" class="darkgrey"></StoreTable>
              </v-col>
            </v-col>
            <v-col cols="12" class="d-flex flex-wrap">
              <v-col
                cols="8"
                class=""
                v-if="
                  storedetails.data.businessAddressLine1 != '' &&
                  storedetails.data.businessPostcode != ''
                "
                style=""
              >
                <v-btn
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    storedetails.data.name +
                    ',' +
                    storedetails.data.businessAddressLine1 +
                    ',' +
                    storedetails.data.businessPostcode
                  "
                  class="font-weight-bold primary white--text rounded-of removetexttransform"
                  style="height: 5em"
                  depressed
                >
                  <v-icon class="white--text" x-large>icons8-place-marker</v-icon>
                  <div class="title full-width text-center pl-2 shop-link">
                    {{ $t("gototheshop") }}
                  </div>
                </v-btn>
              </v-col>
              <v-col
                cols="4"
                class="d-flex justify-end"
                v-if="storedetails.data.businessTelephone != null"
              >
                <v-btn
                  :href="'tel:' + storedetails.data.businessTelephone"
                  class="primary rounded-of"
                  style="height: 5em"
                  depressed
                >
                  <v-icon class="white--text" x-large>icons8-phone</v-icon>
                  <div v-if="storedetails.data.businessTelephone" class="title pl-2 mobile-number">
                    <!-- uniform layout for phone numbers -->
                    {{ setPhoneNumber() }}
                  </div>
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ref, getDownloadURL } from "firebase/storage";
// import store from '@/plugins/store';
export default {
  name: "StoreView",
  data: () => ({
    userFavourited: false,
    storeTitle: "",
    favouriteId: "",
    storeStatus: "",
    storeType: "",
    storeIMG: "",
    storeIMGSquare: "",
    statusColor: "black--text",
    storeTel: "",
    stat: {
      statType: "storeFavourite",
      userId: "",
      orgId: "",
      deleted: false,
    },
    socialLinks: {
      facebook: "",
      twitter: "",
      instagram: "",
      website: "",
    },
    restore: {
      deleted: false,
    },
    storedetails: {
      data: {
        name: "",
        type: "",
        businessAddressLine1: "",
        businessAddressLine2: "",
        businessTown: "",
        businessCounty: "",
        businessPostcode: "",
        businessEmail: "",
        businessTelephone: "",
        businessBidNo: "",
        businessRatesRefNo: 123456, //TODO - add the business rates ref no multiple choice, and change schema to array
        businessImageID: "",
        businessImagetype: "",
        businessBidOffersClaimed: 0,
        deleted: false,
        createdUserId: "",
        businessStatus: "Open",
      },
    },
    loading: false,
    accessibilityTags: {
      billy_chip_seller: "Billy chip seller",
      accepts_gift_card: "Accepts gift card", // Assuming you want these other properties as well
      accepts_loyalty_card: "Accepts loyalty Card",
      disabled_accessible: "Disabled accessible",
      disabled_accessible_verified: "Disabled accessible (verified)",
      first_aider_available: "First aider available",
      autism_friendly: "Autism friendly",
      dementia_friendly: "Dementia friendly",
      offer_available: "Offer available (click to show)",
      welsh_available: "Welsh available",
      bsl_available: "BSL available",
      student_discount_available: "Student discount available",
      public_toilet: "Public accessible toilet",
    },
    storeOffers: [],
    storeCouponVisible: false,
  }),
  components: {
    StoreTable: () => import("@/components/table/StoreTable.vue"),
    OfferCard: () => import("@/components/card/OfferCard.vue"),
  },
  computed: {
    ...mapGetters({
      GET_currentUserProfile: "GET_currentUserProfile",
    }),
    filteredAccessibilities() {
      return this.storedetails.data.businessAccessibilities.filter(
        (tag) => tag !== "accepts_loyalty_card"
      );
    },
  },
  methods: {
    async getStoreDetails() {
      this.loading = true;
      //console.log("orgID: "+this.datadown.orgId)
      this.storedetails = await this.REDIS_read("org", this.$route.params.entityId);
      this.loading = true;
      let t = this;
      if (t.storedetails.data.businessSquareImageID !== ("" || null)) {
        const storageRef = ref(
          t.$firebase.publicStorage,
          "orgImages" +
            "/" +
            t.storedetails.data.businessSquareImageID +
            "." +
            t.storedetails.data.businessSquareTypeID
        );
        await getDownloadURL(storageRef).then((url) => {
          if (url !== undefined) {
            //console.log("download URL: "+url);
            t.storeIMG = url;
          } else {
            t.storeIMG = "";
          }
        });
      } else {
        t.storeIMG = t.MIX_getImgUrl("StoreHoldingBanner.jpg");
      }

      //console.log("Store Deets: "+JSON.stringify(this.storedetails.data.businessImageID))
      this.checkStoreStatus();
      this.setPhoneNumber();
      this.getOffers();
      this.loading = false;
    },
    typeToIcon(storetype) {
      //console.log(storetype)
      if (storetype === "restaurant") {
        return "icons8-cutlery";
      } else if (storetype === "retail") {
        return "icons8-v-neck-t-shirt";
      } else if (storetype === "bar") {
        return "icons8-guinness-beer";
      } else {
        return undefined;
      }
    },
    async getFavourite() {
      return new Promise((resolve, reject) => {
        // console.log("UserId"+ this.GET_currentUserProfile.entityId)
        // console.log(JSON.stringify(this.storedetails.entityId))
        if (this.GET_currentUserProfile != null) {
          this.REDIS_searchFor(
            "stats",
            "",
            "",
            "",
            "",
            `@statType:{storeFavourite} @userId:{${this.GET_currentUserProfile.entityId}} @orgId:{${this.$route.params.entityId}}`
          )
            .then((result) => {
              if (result.data.total >= 0) {
                this.favouriteId = result.data.documents[0]?.entityId;
                // console.log("ID: "+JSON.stringify(this.favouriteId))
                //console.log(result.data.documents[0].deleted)
                if (result.data.documents[0]?.deleted === false) {
                  this.userFavourited = true;
                } else {
                  this.userFavourited = false;
                }
              } else {
                this.userFavourited = false;
              }
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        } else if (localStorage.userToken) {
          this.REDIS_searchFor(
            "stats",
            "",
            "",
            "",
            "",
            `@statType:{storeFavourite} @userId:{${this.MIX_tokenToUser(
              localStorage.userToken
            ).oid.replace(/-/g, "")}} @orgId:{${this.$route.params.entityId}}`
          )
            .then((result) => {
              if (result.data.total >= 0) {
                this.favouriteId = result.data.documents[0].entityId;
                // console.log("ID: "+JSON.stringify(this.favouriteId))
                //console.log(result.data.documents[0].deleted)
                if (result.data.documents[0].deleted === false) {
                  this.userFavourited = true;
                } else {
                  this.userFavourited = false;
                }
              } else {
                this.userFavourited = false;
              }
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    async toggleFavourite() {
      this.loading = true;
      if (this.userFavourited) {
        await this.REDIS_delete("stats", this.favouriteId);
        this.userFavourited = false;
        this.getFavourite();
      } else {
        if (
          this.favouriteId === "" ||
          this.favouriteId === undefined ||
          this.favouriteId === null
        ) {
          if (this.GET_currentUserProfile != null) {
            this.stat.userId = this.GET_currentUserProfile.entityId;
          } else if (localStorage.userToken) {
            this.stat.userId = this.MIX_tokenToUser(localStorage.userToken).oid.replace(/-/g, "");
            //console.log("set user ID to "+this.stat.userId)
          } else {
            // console.log("No user logged in")
            this.loading = false;
            this.userFavourited = false;
            return;
          }
          this.stat.orgId = this.$route.params.entityId;
          this.stat.deleted = false;
          //console.log(JSON.stringify(this.stat))
          await this.REDIS_create("stats", this.stat);
          this.userFavourited = true;
          this.getFavourite();
        } else {
          // console.log("Restore")
          await this.REDIS_restore("stats", this.favouriteId, this.restore);
          this.getFavourite();
        }
      }
      this.loading = false;
    },
    // convertWorkingHoursTo24HourClock(workingHours) {
    //   // Split the time string into start and end times
    //   const [startTime, endTime] = workingHours.split('-');
    //   // console.log(startTime)
    //
    //   // Extract hours and minutes from the start time
    //   const [startHours, startMinutes] = startTime.split(':');
    //   const startHoursInt = parseInt(startHours)
    //   const startMinutesInt = parseInt(startMinutes)
    //
    //   // console.log("START: "+startHoursInt+" "+startMinutesInt)
    //
    //   // Extract hours and minutes from the end time
    //   const [endHours, endMinutes] = endTime.split(':');
    //   const endHoursInt = parseInt(endHours)
    //   const endMinutesInt = parseInt(endMinutes)
    //
    //   // console.log("END: "+endHoursInt+" "+endMinutesInt)
    //
    //   // Convert AM/PM to 24-hour format if needed
    //   let start = 0;
    //   let end = 0;
    //   if(startTime === '12pm'){
    //     start = 12;
    //   }else if(startTime === '12am'){
    //     start = 0;
    //
    //   }else{
    //     start = startTime.includes('pm') ? startHoursInt + 12 : startHoursInt;
    //   }
    //
    //   if(endTime === '12pm'){
    //     end = 12;
    //   }else if(endTime === '12am'){
    //     end = 0;
    //
    //   }else{
    //     end = endTime.includes('pm') ? endHoursInt + 12 : endHoursInt;
    //   }
    //
    //   let startDecimal = 0;
    //   let endDecimal = 0;
    //
    //   if(!isNaN(startMinutesInt)){
    //     // Calculate the decimal representation of the times
    //     startDecimal = start + startMinutesInt / 60;
    //   }else{
    //     startDecimal = start
    //   }
    //
    //   if(!isNaN(endMinutesInt)){
    //     // Calculate the decimal representation of the times
    //     endDecimal = end + endMinutesInt / 60;
    //   }else{
    //     endDecimal = end
    //   }
    //
    //   // Return the resulting array
    //   return [startDecimal, endDecimal];
    // },
    convertTo24HourNew(timeStr, startOrEnd) {
      const match = timeStr.match(/(\d+)(?::(\d+))?([ap]m)?/i);
      if (!match) return { hours: 0, minutes: 0 };

      // eslint-disable-next-line no-unused-vars
      let [_, hours, minutes, period] = match;
      hours = parseInt(hours);
      minutes = parseInt(minutes) || 0;

      if (period) {
        period = period.toLowerCase();
        if (period === "pm" && hours !== 12) {
          hours += 12;
        } else if (period === "am" && hours === 12) {
          hours = 0;
        }

        if (startOrEnd === "End" && period === "am") {
          hours += 12;
        }
      }

      return { hours, minutes };
    },
    isCurrentTimeWithinTimeframe(rangeString) {
      const now = new Date();

      // Split the range string into start and end times
      const [startTime, endTime] = rangeString.split("-").map((time) => time.trim());

      // Convert the start and end times to 24-hour format
      const startParts = startTime.match(/(\d+)(?::(\d+))? ?(am|pm)/i);
      const endParts = endTime.match(/(\d+)(?::(\d+))? ?(am|pm)/i);

      if (!startParts || !endParts) {
        // Invalid time format
        return false;
      }

      let startHour = parseInt(startParts[1], 10);
      const startMinute = parseInt(startParts[2] || 0, 10);
      const startMeridian = startParts[3].toLowerCase();

      let endHour = parseInt(endParts[1], 10);
      const endMinute = parseInt(endParts[2] || 0, 10);
      const endMeridian = endParts[3].toLowerCase();

      // Convert hours to 24-hour format
      if (startMeridian === "pm" && startHour !== 12) {
        startHour += 12;
      }

      //12am = 0:00:00
      if (startMeridian === "am" && startHour === 12) {
        startHour = 0;
      }

      if (endMeridian === "am" && endHour === 12) {
        // Special case: "12am" means the end of the next day
        endHour = 24; // 24-hour format
      } else if (endMeridian === "pm" && endHour !== 12) {
        endHour += 12;
      }

      // Create Date objects with the current date and the parsed times
      const startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        startHour,
        startMinute
      );
      let endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute);

      // If the end time is am, add a day to the end date
      if (endMeridian === "am" && endHour !== 12) {
        endDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1,
          endHour,
          endMinute
        );
      }

      // Debugging logs
      // console.log('startDate: ', startDate)
      // console.log('endDate: ', endDate)
      // console.log('now: ', now)

      // Check if the current time is within the range
      if (startDate <= now && now <= endDate) {
        return true;
      } else {
        return false;
      }
    },
    checkStoreStatus() {
      //get current date
      const d = new Date();
      let day = d.getDay();

      //custom week order to allow for monday as first day of week
      const weekorder = [6, 0, 1, 2, 3, 4, 5];

      //check if store open times are available
      if (this.storedetails.data.businessOpenTimes[0] === "Data not available") {
        this.storeStatus = "N/A";
        return;
      }

      //check if store closed on today
      if (this.storedetails.data.businessOpenTimes[weekorder[day]] === "Closed") {
        this.storeStatus = "Closed";
        this.statusColor = "red--text";
        return;
      }

      //check if store is open
      if (
        this.isCurrentTimeWithinTimeframe(this.storedetails.data.businessOpenTimes[weekorder[day]])
      ) {
        this.storeStatus = "Open";
        this.statusColor = "green--text";
        // console.log("Store is open")
      } else {
        this.storeStatus = "Closed";
        this.statusColor = "red--text";
        // console.log("Store is closed")
      }
    },
    setPhoneNumber() {
      let bizPhone = "";
      if (this.storedetails.data.businessTelephone) {
        bizPhone = this.storedetails.data.businessTelephone.replace(/\D+/g, "");
        if (bizPhone.charAt(0) === "0") {
          bizPhone = bizPhone.slice(0, 5) + " " + bizPhone.slice(5);
        } else if (bizPhone.charAt(0) === "4") {
          bizPhone = bizPhone.slice(2);
          bizPhone = "0" + bizPhone.slice(0, 4) + " " + bizPhone.slice(4);
        } else if (bizPhone.length === 6) {
          bizPhone = "01792 " + bizPhone;
        } else if (bizPhone.length < 6) {
          bizPhone = null;
        } else if (bizPhone.charAt(0) !== "0") {
          bizPhone = "0" + bizPhone.slice(0, 4) + " " + bizPhone.slice(4);
        }
      }
      return bizPhone;
    },
    async sendStat() {
      const stat = {
        statType: "storeView",
        userId: this.GET_currentUserProfile?.entityId || "N/A",
        orgId: this.$route.params.entityId,
        deleted: false,
      };
      await this.REDIS_create("stats", stat);
    },
    // Gets associated offers for the store
    getOffers() {
      return new Promise((resolve, reject) => {
        this.REDIS_searchFor("offer", "", "", "", "", `@deleted:{false}`)
          .then((result) => {
            this.offers = result.data.documents;
            this.offers.filter((offer) => {
              if (offer.orgId === this.storedetails.data.entityId) {
                this.storeOffers.push(offer);
              }
            });
            // console.log(this.storeOffers.length)
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // shows/hides available this store's available coupons
    showStoreCoupons() {
      this.storeCouponVisible = !this.storeCouponVisible;
    },
  },
  created() {
    this.sendStat();
    this.getStoreDetails();
    this.getFavourite();
  },
};
</script>

<style scoped>
.marker-list {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;
}

.marker-list .marker {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cd2027;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}
/* .marker-list .link {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cd2027;
  border-radius: 10px;
  overflow: hidden;
} */

.marker i {
  font-size: 32px;
}

.marker .link {
  font-size: 24px;
}

.marker-list .marker img {
  width: 100%;
  height: 100%;
}

.v-image.store-img {
  width: 15em;
  height: 15em;
}

.noboxshadow {
  box-shadow: none !important;
}
.imgpopout {
  position: absolute;
  height: 11em;
  width: 11em;
  left: 5%;
  margin-top: -1em;
}

.belowimgpopout {
  padding-top: 10em;
}

.storeicons {
  padding-right: 3em !important;
}

@media only screen and (min-width: 768px) {
  .imgpopout {
    height: 12em;
    width: 95%;
    left: 2.5%;
  }
  .belowimgpopout {
    padding-top: 11em;
  }
}

@media only screen and (max-width: 576px) {
  .v-image.store-img {
    width: 100%;
  }
}
</style>
<style scoped>
/* Default state (desktop) - show the elements */
.shop-link,
.mobile-number {
  display: block;
}

/* Mobile state - hide the elements */
@media only screen and (max-width: 768px) {
  .shop-link,
  .mobile-number {
    display: none;
  }
}
</style>
