<template>
    <div class="paddingmobile">
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/admin/dashboard')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
        <div class="pl-10 pr-10">
            <v-row>
                <v-col cols="6" xs="6" sm="6">
                    <div class="title font-weight-black primary--text">{{ $t("events") }}</div>
                </v-col>
            </v-row>
            <!-- PAGE CONTENT -->
            <v-row class="lightgrey rounded-of w-100 justify-space-between">
                <v-col cols="8" xs="6" sm="6" class="">
                    <SearchField :placeholder="$t('search')+ '...'" v-model="search" background="white"
                        style=" max-width: 17em;" />
                </v-col>
                <v-col cols="4" class="d-flex justify-end" style="padding-right: 1.5em;">
                    <AddEvent v-on:addedEvent="initialise()" :key="addEventKey" />
                </v-col>
                <!-- <v-col cols="12" xs="12" sm="12" class="pa-6" v-for="(event, index) in events" :key="index">
                    <pre> {{ event }}</pre>
                </v-col> -->
                <v-col cols='12'>
                    <v-data-table :items-per-page="pageCount" mobile-breakpoint="0" :items="events" :search="search" :headers="eventHeaders" class="roundedtable">
                        <!-- <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Events</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                    hide-details></v-text-field>
                            </v-toolbar>
                        </template> -->
                        <template v-slot:[`item.approvalStatus`]="{ item }">
                            <EventApproved :datadown="item" :userLevel="GET_currentUserProfile.level"/>
                        </template>
                        <template v-slot:[`item.eventName`]="{ item }">
                            <div>{{ item.eventName }}</div>
                        </template>

                        <template v-slot:[`item.eventDateTime`]="{ item }">
                            <div>{{ MIX_formatDateTime(item.eventDateTime, 'YYYY-MM-DDTHH:mm:ssZ[Z]', 'DD MMMM YYYY') }}</div>
                        </template>
                        <template v-slot:[`item.eventFinishDate`]="{ item }">
                            <div>{{ MIX_formatDateTime(item.eventFinishDate, 'YYYY-MM-DDTHH:mm:ssZ[Z]', 'DD MMMM YYYY') }}</div>
                        </template>

                        <template v-slot:[`item.eventOpenClose`]="{ item }">
                            <div>{{ item.eventOpenClose }}</div>
                        </template>
                        <template v-slot:[`item.eventRepeatType`]="{ item }">
                            <div style="text-transform: capitalize">
                                {{ item.eventRepeatType ? item.eventRepeatType : 'No repeat' }}
                            </div>
                        </template>

                        <template v-slot:[`item.eventDetails`]="{ item }">
                            <div>{{ item.eventDetails.substring(0, 50) + "..." }}</div>
                        </template>

                        <template v-slot:[`item.edit`]="{ item }">
                            <div><EditEvent :datadown="item" v-on:edited="initialise()" /></div>
                        </template>

                        <template v-slot:[`item.delete`]="{ item }">
                            <div><DeleteDialog collection="event" :entityId="item.entityId" :itemName="item.eventName" v-on:deleted="initialise()" /></div>
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventApproved from "@/components/util/EventApproved.vue";
export default {
    name: "AdminEventsView",
    data: () => ({
        loading: false,
        events: [],
        search: "",
        pageNo: 1,
        pageCount: 10,
        addEventKey: 0,
        eventHeaders: [
            { text: "Approval Status", value: "approvalStatus"},
            { text: "Name", value: "eventName" },
            { text: "Open Date", value: "eventDateTime" },
            { text: "Close Date", value: "eventFinishDate" },
            { text: "Event Open/Close Time", value: "eventOpenClose" },
            { text: "Repeat", value: "eventRepeatType" },
            { text: "Event details", value: "eventDetails" },
            { text: "Edit", value: "edit" },
            { text: "Delete", value: "delete" },],
        items: {
            eventName: "",
            eventDateTime: "",
            eventFinishDate: "",
            eventOpenClose: "",
            eventRepeatType: "",
            eventDetails: "",
        },
        eventCount: 0,
    }),
    components: {
        EventApproved,
        AddEvent: () => import("@/components/AddEvent.vue"),
        EditEvent: () => import("@/components/EditEvent.vue"),
        DeleteDialog: () => import("@/components/util/DeleteDialog.vue"),
    },
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile',
        }),
        computedSearch(){
            var onlyLetters = /^[a-zA-Z][a-zA-Z]*[a-zA-Z]$/;
            if(this.search.length > 3 && onlyLetters.test(this.search)){
                return this.search.trim() + "*"
            }else{
                return ""
            }
        },
        computedStoreSearch(){
            if(this.search.length > 3){
                return this.search + "*"
            }else{
                return ""
            }
        }
    },
    methods: {
        async initialise() {
            this.loading = true;
            await this.getEventCount()
            await this.getEvents();
            this.addEventKey++;
            this.loading = false;
        },
        // Gets count of how many events are in the database matching rules and feeds to getEvents(), as it needs to have a count value to get all events
        async getEventCount() {
            return new Promise((resolve, reject) => {
                // this.REDIS_countFor("event", "", "", "createdDateTime", "desc", `@deleted:{false}@createdUserId:{Fpr8J2yNPqefB5hVteuAkOoAAZG3}`)
                const userDetails = this.GET_currentUserProfile;
                const userCreator = userDetails.entityId;
                const userLevel = userDetails.level;
                if (userLevel === 'superadmin') {
                    this.REDIS_countFor("event", "", "", "createdDateTime", "desc", `@deleted:{false}`)
                    .then((result) => {
                        this.eventCount = result.total;
                        resolve(result);
                        console.log('EVENTS-getEventCount-SUPER', result)
                    })
                    .catch((error) => {
                        reject(error);
                    });
                } else {
                    this.REDIS_countFor("event", "", "", "createdDateTime", "desc", `@deleted:{false}@createdUserId:{${userCreator}}`)
                    .then((result) => {
                        this.eventCount = result.total;
                        resolve(result);
                        console.log('EVENTS-getEventCount-USER', result)
                    })
                    .catch((error) => {
                        reject(error);
                    });
                }
            });
        },
        async getEvents(){
            return new Promise((resolve, reject) => {
                const userDetails = this.GET_currentUserProfile;
                const userCreator = userDetails.entityId;
                const userLevel = userDetails.level;
                if (userLevel === 'superadmin') {
                    this.REDIS_searchFor("event", 0, this.eventCount,"createdDateTime", "desc", `@deleted:{false}`)
                    .then((result) => {
                        this.events = result.data.documents;
                        // Show only live events
                        // this.events = this.events.filter((event) => event.deleted !== true);
                        // console.log('EVENTS', this.events[0])
                        resolve(result);
                        console.log('EVENT-getEvents-SUPER', this.events)
                    })
                    .catch((error) => {
                        reject('ERROR =>', error);
                    });
                } else {
                    this.REDIS_searchFor("event", 0, this.eventCount,"createdDateTime", "desc", `@deleted:{false}@createdUserId:{${userCreator}}`)
                    .then((result) => {
                        this.events = result.data.documents;
                        resolve(result);
                        console.log('EVENT-getEvents-USER', this.events)
                    })

                // this.REDIS_searchFor("event", 0, this.eventCount,"createdDateTime", "desc", `@deleted:{false}`)
                // .then((result) => {
                //     this.events = result.data.documents;
                //     // Show only live events
                //     // this.events = this.events.filter((event) => event.deleted !== true);
                //     // console.log('EVENTS', this.events[0])
                //     resolve(result);
                //     console.log('EVENT-getEvents', this.events)
                // })
                // .catch((error) => {
                //     reject('ERROR =>', error);
                // });
                }
            })
        },
    },
    created() {
        this.initialise();
    }
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}

.paragraphtext {
    line-height: 1.4rem !important;
}

.roundedtable{
    border-radius: 1rem !important;
}

/* Bottom pagination buttons*/
.mdi-chevron-right{
    color: #CC0400 !important;
}

.mdi-chevron-left{
    color: #CC0400 !important;
}

/* Top header hover arrows*/
.v-data-table-header__icon {
  color: #CC0400 !important;
}

/* .v-data-table-header{
    background-color: #CC0400 !important;
} */
</style>
