<template>
    <div>
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/home')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
        <v-container>
            <v-row class="">
                <v-col cols="12" class="">
                    <div class="title pl-4">{{ $t("cardlist") }}</div>
                </v-col>
                <v-col cols="12" sm="6" class="" :class="$vuetify.breakpoint.width <=700 ? 'pt-10' : ''" >
                    <v-row class="lightgrey rounded-of mx-3 d-flex flex-column align-center justify-space-between" style="min-height: 70vh;">
                        <v-col cols="12" class="">
                            <div class="title font-weight-black black--text text-center">Gift Card</div>
                        </v-col>
                        <v-col cols="12" class="">
                            <v-img :src="MIX_getImgUrl('swanseaGiftCard.png')" contain max-height="150px"/>
                        </v-col>
                        <v-col cols="12" class="">
                            <div class="subtitle-3 text-center px-8">Sign your business up for the <b>Swansea Gift Card</b> and tap into a new revenue stream, increase foot traffic and sales. Accepted in close to 100 local businesses, it's the perfect way to attract new customers and showcase your business.</div>
                        </v-col>
                        <v-col cols="12" sm="10" class="pb-10">
                            <v-btn href="https://form.jotform.com/Miconex/universal-UK-mpl-merc" x-large depressed block
                                    class="primary white--text rounded-of">

                                    <div class="white--text subtitle-3 removetexttransform">Sign up for Gift Cards</div>
                                
                                </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "DefibView",
    data: () => ({
        search: "",
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
    }),
    components: {
    },
    computed: {
        ...mapGetters({
            GET_showFilterDrawer: "GET_showFilterDrawer",
        }),
    },
    methods: {
        ...mapActions({
            ACT_showFilterDrawer: "ACT_showFilterDrawer",
        }),
        updateShowFilterDrawer() {
            this.ACT_showFilterDrawer(!this.GET_showFilterDrawer);
        },
    }
};
</script>

<style>.noboxshadow {
    box-shadow: none !important;

}

.v-carousel__controls {
    margin-top: 200px !important;
}</style>
